import React from "react"

const Host = (props) => {
    return (
        <div className="host">
            <div className="host-image">
                <img src={props.image} alt={props.full_name + ` Headshot. `} ></img>
            </div>

            <div className="host-bio">
                <h3>{props.full_name}</h3>
                {props.children}
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default Host