import React from "react"
import Layout from "../components/layout"
import Tagline from "../components/tagline"
import SEO from "../components/seo"
import Promo from "../components/promo-text"
import Host from "../components/host"
import ParisImg from "../images/img-paris.png"
import TerrenceImg from "../images/img-terrence.png"
import ContactUs from "../components/contact-us"
import Share from "../components/social-share"

const AboutPage = () => (
    <Layout>
        <SEO title="About" />
        <Tagline content="Where our timeline comes to life."/>
        <Promo color="dark">
            <h1>ABOUT THE SERIES</h1>
            <p>“Keep it Social” ties your timeline to the real world in real time. It’s a safe zone for honest, fresh, and engaging conversations about social media. You’ll see the social first generation discuss the latest headlines and we’ll be relying on you to help cover what’s trending on your timelines.</p>
            <p>Do you have story ideas, comments about a segment, or want to get in touch with us? <br /> You’ll find us here:  <a href="mailto:keepitsocial@wgbh.org"  rel="noopener noreferrer" target="_blank" className="mail-link">keepitsocial@wgbh.org</a></p>
            <Share url="https://keepitsocial.org/about/"/>
        </Promo>

        <div className="hosts">
            <div className="wrapper">
                <p className="headline">The Hosts</p>
                <Host
                    image={ParisImg}
                    full_name="Paris Alston"
                    instagram='bonjourparis_'
                    twitter='bonjourparis_'
                    facebook=''
                    description="Bonjour, I'm Paris! I'm a multimedia journalist who has always loved making real connections through social media. A Tar Heel born and bred, I hail from North Carolina and attended UNC Chapel Hill. There are a few basic things I love to do: travel, meet new people, tell stories and make things look good – be it an outfit or an Instagram post – and in many ways these are things that help me make sense of our shared experiences on social media. I'm always down for an adventure and Keep It Social is one itself!"
                >
                    <ul className="social-links">
                       <li class="twitter"><a rel="noopener noreferrer" href="https://www.twitter.com/bonjourparis_" target="_blank">@bonjourparis_</a></li>
                       <li class="instagram"><a rel="noopener noreferrer" href="https://www.instagram.com/bonjourparis_" target="_blank">@bonjourparis_</a></li>
                    </ul>
                </Host>

                <Host
                    image={TerrenceImg}
                    full_name="Terrence Johnson"
                    description="Hey friends! I’m Terrence, the Keep It Social Co-Host! I’m a multimedia journalist and storyteller hailing from Shuqualak, Mississippi where we believe four-wheelers, horses and carriages are one of the most elite forms of transportation. I engage with people through honest conversations and intentional writing.  In 2018, I graduated from the University of Mississippi’s Meek School of Journalism and New Media. Right after that, I packed up and moved to Boston! In 2019, I graduated with honors from Northeastern University’s College of Arts, Media and Design where I am a member of the inaugural Media Advocacy cohort. I’m excited to be at WGBH and to work on Keep It Social. What do I enjoy the most in life? Good food, good music, and good people. "
                >
                    <ul className="social-links">
                       <li class="twitter"><a rel="noopener noreferrer" href="https://twitter.com/hereasterrencej/" target="_blank">@hereasterrencej</a></li>
                       <li class="instagram"><a rel="noopener noreferrer" href="http://instagram.com/hereasterrence.j/" target="_blank">@hereasterrence.j</a></li>
                       <li class="facebook"><a rel="noopener noreferrer" href="https://www.facebook.com/terrence.j.johnson.9" target="_blank">Terrence J. Johnson</a></li>
                    </ul>
                </Host>
            </div>
        </div>
        <ContactUs />
   </Layout>
)

export default AboutPage
