import React from "react"

const Follow = (props) => (
    <ul className="share">
        <li>Share</li>
        <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${props.url}`} rel="noopener noreferrer" target="_blank" className="facebook" title="Click to share on Facebook. ">Share on Facebook</a></li>
        <li><a href={`https://twitter.com/home?status=${props.url}`} rel="noopener noreferrer" target="_blank" className="twitter" title="Click to share on Twitter. ">Share on Twitter</a></li>
    </ul>
)

export default Follow
