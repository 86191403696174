import React from "react"

const ContactUs = () => (
    <div className="contact-us">
        <div className="wrapper">
            <div className="logo-section">
                <img className="logo" src="https://s3.amazonaws.com/wgbh-digital/keepitsocial/images/logo-kis-light.png" alt="Keep it Social Logo. "/>
            </div>
            <div className="social">
                <p className="headline">REACH OUT TO US!</p>
                <div className="lists-wrapper">
                    <ul className="follow">
                        <li>On Social</li>
                        <li>
                            <a rel="noopener noreferrer" href="https://www.youtube.com/channel/UCL8AY9rBzjgtF0LmDWu1OpA/" target="_blank" className="youtube" title="Reach us on YouTube. ">
                                <div className="image youtube"></div>
                                Keep it Social
                            </a>
                        </li>
                        <li>
                            <a rel="noopener noreferrer" href="https://www.twitter.com/kis_wgbh" target="_blank" className="twitter" title="Reach us on Twitter. ">
                                <div className="image twitter"></div>
                                @kis_WGBH
                            </a>
                        </li>
                        <li>
                             <a rel="noopener noreferrer" href="https://www.instagram.com/KeepItSocialWGBH" target="_blank" className="instagram" title="Reach us on Instagram. ">
                                <div className="image instagram"></div>
                               @KeepItSocialWGBH
                            </a>
                        </li>
                        <li>
                            <a rel="noopener noreferrer" href="https://www.facebook.com/KeepItSocialWGBH/" target="_blank" className="facebook" title="Reach us on Facebook. ">
                                <div className="image facebook"></div>
                                @KeepItSocialWGBH
                            </a>
                        </li>

                        <li>#keepitsocial</li>
                    </ul>

                    <ul className="email">
                        <li>By Email</li>
                        <li>
                            <a href="mailto:keepitsocial@wgbh.org"  rel="noopener noreferrer" target="_blank" className="mail-link">keepitsocial@wgbh.org</a>
                        </li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
)

export default ContactUs